var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"company"},[_vm._v(_vm._s(_vm.bottomObj.companyName))]),_c('div',{staticClass:"rights"},[_c('span',[_vm._v(_vm._s(_vm.bottomObj.companyVersion && _vm.bottomObj.companyVersion.split("黔ICP")[0]))]),_c('a',{staticStyle:{"margin-left":"10px"},attrs:{"href":"https://beian.miit.gov.cn"}},[_vm._v(_vm._s(_vm.bottomObj.companyVersion && _vm.bottomObj.companyVersion.split(" ")[6]))]),_c('img',{staticStyle:{"margin-right":"4px","vertical-align":"middle","margin-left":"10px"},attrs:{"src":require("../assets/images/police.png"),"alt":""}}),_c('a',{attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=贵公网安备52012302007221号"}},[_vm._v(_vm._s(_vm.bottomObj.companyVersion && _vm.bottomObj.companyVersion.split(" ")[10] + _vm.bottomObj.companyVersion.split(" ")[11]))])])]),_c('div',{staticClass:"icon"},[_c('div',{staticClass:"qrcode"},[_c('img',{attrs:{"src":_vm.bottomObj.hiringImg && _vm.bottomObj.hiringImg.indexOf('http') >= 0
              ? _vm.bottomObj.hiringImg
              : _vm.imgBaseUrl + _vm.bottomObj.hiringImg,"alt":""}}),_c('br'),_c('span',[_vm._v("人才招聘")])]),_c('div',{staticClass:"qrcode"},[_c('img',{attrs:{"src":_vm.bottomObj.tiktokImg && _vm.bottomObj.tiktokImg.indexOf('http') >= 0
              ? _vm.bottomObj.tiktokImg
              : _vm.imgBaseUrl + _vm.bottomObj.tiktokImg,"alt":""}}),_c('br'),_c('span',[_vm._v("官方抖音")])]),_c('div',{staticClass:"qrcode"},[_c('img',{attrs:{"src":_vm.bottomObj.wechatImg && _vm.bottomObj.wechatImg.indexOf('http') >= 0
              ? _vm.bottomObj.wechatImg
              : _vm.imgBaseUrl + _vm.bottomObj.wechatImg,"alt":""}}),_c('br'),_c('span',[_vm._v("微信公众号")])]),_c('div',{staticClass:"lx"},[_c('span',[_vm._v("业务咨询")]),_c('span',[_vm._v(_vm._s(_vm.bottomObj.workTime))]),_c('span',[_vm._v(_vm._s(_vm.bottomObj.phone))])])])]),_c('div',{staticClass:"mFooter"},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"qrcode"},[_c('img',{attrs:{"src":_vm.bottomObj.wechatImg && _vm.bottomObj.wechatImg.indexOf('http') >= 0
              ? _vm.bottomObj.wechatImg
              : _vm.imgBaseUrl + _vm.bottomObj.wechatImg,"alt":"wechat"}}),_c('br'),_c('span',[_vm._v("微信公众号")])]),_c('div',{staticClass:"qrcode"},[_c('img',{attrs:{"src":_vm.bottomObj.tiktokImg && _vm.bottomObj.tiktokImg.indexOf('http') >= 0
              ? _vm.bottomObj.tiktokImg
              : _vm.imgBaseUrl + _vm.bottomObj.tiktokImg,"alt":"tiktok"}}),_c('br'),_c('span',[_vm._v("官方抖音")])]),_c('div',{staticClass:"qrcode"},[_c('img',{attrs:{"src":_vm.bottomObj.hiringImg && _vm.bottomObj.hiringImg.indexOf('http') >= 0
              ? _vm.bottomObj.hiringImg
              : _vm.imgBaseUrl + _vm.bottomObj.hiringImg,"alt":"hiring"}}),_c('br'),_c('span',[_vm._v("人才招聘")])])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"company"},[_vm._v(_vm._s(_vm.bottomObj.companyName))]),_c('div',{staticClass:"rights"},[_c('span',[_vm._v(_vm._s(_vm.bottomObj.companyVersion && _vm.bottomObj.companyVersion.split("黔ICP")[0]))]),_c('a',{attrs:{"href":"https://beian.miit.gov.cn"}},[_vm._v(_vm._s(_vm.bottomObj.companyVersion && _vm.bottomObj.companyVersion.split(" ")[6]))]),_c('img',{staticStyle:{"margin-right":"4px","vertical-align":"middle"},attrs:{"src":require("../assets/images/police.png"),"alt":""}}),_c('a',{attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=贵公网安备52012302007221号"}},[_vm._v(_vm._s(_vm.bottomObj.companyVersion && _vm.bottomObj.companyVersion.split(" ")[10] + _vm.bottomObj.companyVersion.split(" ")[11]))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }