import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./styles/reset.scss"
import "./styles/font.scss"
import { Icon, Input, Button, Dialog, Message, Popover, Loading } from 'element-ui'
import CenterComponent from "@/components/CenterComponent"
import '@/utils/rem'
import 'animate.css'

Vue.config.productionTip = false
Vue.use(Icon)
Vue.use(Input)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Popover)
Vue.use(Loading.directive)
Vue.prototype.$message = Message
Vue.component('CenterComponent', CenterComponent)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
